import { useEffect, useState } from 'react';
import asdfgh from 'asdfgh';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Styles from './Password.module.scss';
import InputStyles from '../Input.module.scss';

const Password = ({
  value: initValue = '',
  name = 'password',
  onKeyPress,
  onChange = null,
  autoFocus = true,
  minLength = 5,
  minScore = 2,
  placeholder = 'Password',
  showStrength = false,
  error: initError = null,
  onFocus = () => {},
  onBlur = () => {},
}) => {
  // This is a copied and updated version of PasswordStrength
  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     error: error || null,
  //     score: 0,
  //     isValid: null,
  //     value: value || '',
  //     togglePassword: false,
  //   };
  // }

  const [score, setScore] = useState(0);
  const [isValid, setIsValid] = useState(!initError);
  const [value, setValue] = useState(initValue || '');
  const [togglePassword, setTogglePassword] = useState(false);
  const [error, setError] = useState(null);
  const [result, setResult] = useState({});

  useEffect(() => {
    if (initError) {
      setIsValid(!initError);
      setError(initError);
    }
  }, [initError]);

  // const [info, setInfo] = useState({
  //   error: error || null,
  //   score: 0,
  //   isValid: null,
  //   value: value || '',
  //   togglePassword: false,
  // });

  // if (error !== info.error) {
  //   info.error = error || null;
  //   info.isValid = !error;
  // }

  const handleFocus = (event) => {
    try {
      onFocus(event);
    } catch (err) {
      return;
    }
  };

  const handleBlur = (event) => {
    try {
      onBlur(event);
    } catch (err) {
      return;
    }
  };

  const isTooShort = (password) => {
    return password.length < minLength;
  };

  const handleToggle = () => {
    setTogglePassword(!togglePassword);
    // if (info.togglePassword) {
    //   setInfo((prevState) => ({ ...prevState, togglePassword: false }));
    // } else {
    //   setInfo((prevState) => ({ ...prevState, togglePassword: true }));
    // }
  };

  const getResult = async (val) => {
    return await asdfgh(val);
    // return result;
  };

  const handleChange = (e) => {
    let newScore = 0;
    let newError = null;
    // let result = null;

    // always sets a zero score when min length requirement is not met
    // which avoids unnecessary zxcvbn computations (they require quite lots of CPU)
    if (showStrength && e.target.value && !isTooShort(e.target.value)) {
      Promise.resolve(
        getResult(e.target.value).then((data) => {
          setResult(data);
        })
      );
      // const result = await getResult(e.target.value);
      newScore = result?.score;

      try {
        newError = result?.feedback.suggestions.slice(-1)[0];
      } catch (err) {
        newError = null;
      }
    }

    setValue(e.target.value);
    if (showStrength) {
      // setInfo((prevState) => ({
      //   ...prevState,
      //   error: error,
      //   value: e.target.value,
      //   isValid: score >= minScore,
      //   score,
      // }));
      setError(newError);
      setIsValid(e.target.value && newScore >= minScore);
      setScore(newScore);

      // if (onChange !== null) {
      //   // Send back to parent for additional handlers
      //   onChange(e);
      // }
    } else {
      e.persist();
      // Need to persist it to keep it in async callbacks
      // setInfo((prevState) => ({
      //   ...prevState,
      //   error: error,
      //   value: e.target.value,
      //   isValid: !info.error,
      // }));
      setError(error);
      setIsValid(!error);
      // if (onChange !== null) {
      //   // Send back to parent for additional handlers
      //   onChange(e);
      // }
    }

    onChange?.(e);
  };

  const handleKeyPress = (e) => {
    if (onKeyPress) {
      onKeyPress(e);
    }
  };

  // const { score, value, isValid, error, togglePassword } = info;

  const strengthClasses = [
    Styles.isStrength0,
    Styles.isStrength1,
    Styles.isStrength2,
    Styles.isStrength3,
    Styles.isStrength4,
  ];

  const toogleText = togglePassword ? 'hide' : 'show';
  const passwordType = togglePassword ? 'text' : 'password';

  return (
    <div
      className={classNames(InputStyles.container, error && InputStyles.containerError, {
        [strengthClasses[score]]: value.length > 0,
      })}>
      <input
        className={classNames(InputStyles.base, InputStyles.input)}
        name={name}
        id='password'
        type={passwordType}
        value={value}
        autoComplete='off'
        placeholder={placeholder}
        autoFocus={autoFocus}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyPress={handleKeyPress}
      />
      <label
        htmlFor='password'
        className={classNames(InputStyles.label, isValid === false && InputStyles.error)}>
        {(isValid === false && error) || placeholder}
      </label>
      <span className={Styles.button} onClick={handleToggle}>
        {toogleText}
      </span>
      {showStrength && (
        <div className={classNames(Styles.strength, value && Styles['score' + score])} />
      )}
    </div>
  );
};

export default Password;

Password.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  onKeyPress: PropTypes.func,
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
  minLength: PropTypes.number,
  minScore: PropTypes.number,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  showStrength: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
